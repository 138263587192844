import { ZONES } from 'constants/zones';

// Lip Bar Shades
import nude from 'assets/filters/lip-bar-nude.svg';
import pink from 'assets/filters/lip-bar-pink.svg';
import berries from 'assets/filters/lip-bar-berries.svg';
import reds from 'assets/filters/lip-bar-reds.svg';
import corals from 'assets/filters/lip-bar-corals.svg';
import { SWATCH_CHECKBOX_NAMES } from './swatchCheckbox/names';


// Map to filterable sections from site_import.xlsx file
const FILTER_KEYS = {
    CATEGORY: 'category',
    COLOR: 'color',
    FINISH: 'finish',
    ZONE: 'zone',
    NEW: 'new'
};

export const isValidFilter = filterName => {
    return Object.keys(FILTER_KEYS).some(key => FILTER_KEYS[key] === filterName);
};

export const FILTER_TYPES = {
    CHECKBOXES: 'checkboxes',
    IMAGES: 'images'
};

export const FILTER_TEXT = {
    NEW: 'New',
    LOVED: 'Soho Favorites'
};

export const FLORAL_FILTER_VALUE = {
    key: FILTER_KEYS.CATEGORY,
    swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
    string: 'Floral',
    strings: [
        'Floral',
        'Light Floral',
        'Intense Floral'
    ]
};

export const AMBER_FILTER_VALUE = {
    key: FILTER_KEYS.CATEGORY,
    swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
    string: 'Amber',
    strings: [
        'Soft Amber',
        'Rich Amber'
    ]
};

export const FILTERS = [
    {
        zone: ZONES.CARE,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Serums',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8,
                            string: 'Serum'
                        }
                    },
                    {
                        text: 'Moisturizers',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3,
                            string: 'Moisturizers'
                        }
                    },
                    {
                        text: 'Hydration Boosters',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2,
                            string: 'Hydration Boosters'
                        }
                    }, {
                        text: 'Lip & Eye Treats',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1,
                            string: 'Eye & Lip Treats'
                        }
                    },
                    {
                        text: 'Sun Protection',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_3,
                            string: 'Sun Protection'
                        }
                    },
                    {
                        text: 'Body Care',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_5,
                            string: 'Body Care'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.CLEANSE,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Makeup Remover',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2,
                            string: 'Makeup Remover'
                        }
                    },{
                        text: 'Cleansers',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_1,
                            string: 'Cleansers'
                        }
                    }, {
                        text: 'Toners',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_4,
                            string: 'Toner'
                        }
                    },
                    {
                        text: 'Hair & Grooming',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_3,
                            string: 'Hair & Grooming'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.SELF_CARE,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Bath',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_4,
                            string: 'Bath'
                        }
                    },
                    {
                        text: 'Body',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_5,
                            string: 'Body'
                        }
                    },
                    {
                        text: 'Hair',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_3,
                            string: 'Hair'
                        }
                    },
                    {
                        text: 'Grooming',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2,
                            string: 'Grooming'
                        }
                    },
                    {
                        text: 'Tools & Accessories',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Tools & Accessories'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.SKIN_ENHANCERS,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Brushes & Tools',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.GRAY_FACE_DEFINERS_4,
                            string: 'Brushes & Tools'
                        }
                    },
                    {
                        text: 'Foundation',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2,
                            string: 'Foundation'
                        }
                    },
                    {
                        text: 'Base',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8,
                            string: 'Base'
                        }
                    },
                    {
                        text: 'Concealer',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_6,
                            string: 'Concealer'
                        }
                    },
                    {
                        text: 'Tinted Multi-Taskers',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8,
                            string: 'Tinted Multi-Taskers'
                        }
                    },
                    {
                        text: 'Powder',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_4,
                            string: 'Powder'
                        }
                    },
                    {
                        text: 'Prep',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_SKIN_ENHANCERS_7,
                            string: 'Prep'
                        }
                    },{
                        text: 'Even',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8,
                            string: 'Even'
                        }
                    },
                    {
                        text: 'Finish',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_POWDER_3,
                            string: 'Finish'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }

                ]
            }
        ]
    },
    {
        zone: ZONES.FACE_DEFINITION,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Skincare',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2,
                            string: 'Skincare'
                        }
                    },
                    {
                        text: 'Bronzer & Highlighter',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_FACE_DEFINERS_5,
                            string: 'Bronzer & Highlighter'
                        }
                    }, {
                        text: 'Blush',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.PINK_FACE_DEFINERS_2,
                            string: 'Blush'
                        }
                    },
                    {
                        text: 'Palette',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.MULTI_COLOR_FACE_DEFINERS_3,
                            string: 'Palette'
                        }
                    },
                    {
                        text: 'Balm',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_FACE_DEFINERS_1,
                            string: 'Balm'
                        }
                    },
                    {
                        text: 'Brushes',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.GRAY_FACE_DEFINERS_4,
                            string: 'Brushes'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.EYE_DEFINITION,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Base',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_7,
                            string: 'Base'
                        }
                    },
                    {
                        text: 'Mascara',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_8,
                            string: 'Mascara'
                        }
                    },
                    {
                        text: 'Eyeliner',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_9,
                            string: 'Eyeliner'
                        }
                    }, {
                        text: 'Eyeshadow',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_10,
                            string: 'Eyeshadow'
                        }
                    }, {
                        text: 'Brow',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BROWN_FACE_DEFINERS_6,
                            string: 'Brow'
                        }
                    },
                    {
                        text: 'Brushes & Tools',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11,
                            string: 'Brushes & Tools'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.WORKSHOP,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Skin Essentials',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.WHITE_SKIN_ENHANCERS_7,
                            string: 'Skin Essentials'
                        }
                    },
                    {
                        text: 'Skin Treats',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8,
                            string: 'Skin Treats'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.EYE_COLOR,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Category',
                checkboxes: [
                    {
                        text: 'Cream',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.EYE_CREAM_15,
                            string: 'Cream'
                        }
                    },
                    {
                        text: 'Powder',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.EYE_POWDER_16,
                            string: 'Powder'
                        }
                    },
                    {
                        text: FILTER_TEXT.LOVED,
                        value: {
                            key: FILTER_KEYS.ZONE,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
                            string: ZONES.FAVORITES
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.LIP_BAR,
        filters: [
            {
                type: FILTER_TYPES.IMAGES,
                title: 'Colors',
                checkboxes: [
                    {
                        text: 'Nudes',
                        image: nude,
                        imageSelected: nude,
                        value: {
                            key: FILTER_KEYS.COLOR,
                            string: 'Nude'
                        }
                    },
                    {
                        text: 'Pinks',
                        image: pink,
                        imageSelected: pink,
                        value: {
                            key: FILTER_KEYS.COLOR,
                            string: 'Pink'
                        }
                    },
                    {
                        text: 'Berries',
                        image: berries,
                        imageSelected: berries,
                        value: {
                            key: FILTER_KEYS.COLOR,
                            string: 'Plum/Berry'
                        }
                    },
                    {
                        text: 'Reds',
                        image: reds,
                        imageSelected: reds,
                        value: {
                            key: FILTER_KEYS.COLOR,
                            string: 'Red'
                        }
                    },
                    {
                        text: 'Corals',
                        image: corals,
                        imageSelected: corals,
                        value: {
                            key: FILTER_KEYS.COLOR,
                            string: 'Coral'
                        }
                    }
                ]
            },
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Finishes',
                checkboxes: [
                    {
                        text: 'Shine',
                        value: {
                            key: FILTER_KEYS.FINISH,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.RED_LIPS_13,
                            string: 'Shine'
                        }
                    },
                    {
                        text: 'Satin',
                        value: {
                            key: FILTER_KEYS.FINISH,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.RED_LIPS_1,
                            string: 'Satin'
                        }
                    },
                    {
                        text: 'matte',
                        value: {
                            key: FILTER_KEYS.FINISH,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.RED_LIPS_6,
                            string: 'Matte'
                        }
                    }
                ]
            }
        ]
    },
    {
        zone: ZONES.FRAGRANCE,
        filters: [
            {
                type: FILTER_TYPES.CHECKBOXES,
                title: 'Scents',
                checkboxes: [
                    {
                        text: 'Citrus',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Citrus'
                        }
                    },
                    {
                        text: 'Green Floral',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Green Floral'
                        }
                    },
                    {
                        text: 'Intense Floral',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Intense Floral'
                        }
                    },
                    {
                        text: 'Light Floral',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Light Floral'
                        }
                    },
                    {
                        text: 'Aromatic',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Aromatic'
                        }
                    },
                    {
                        text: 'Rich Amber',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Rich Amber'
                        }
                    },
                    {
                        text: 'Soft Amber',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Soft Amber'
                        }
                    },
                    {
                        text: 'Woody',
                        value: {
                            key: FILTER_KEYS.CATEGORY,
                            swatchBoxName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
                            string: 'Woody'
                        }
                    }
                ]
            }
        ]
    }
];
