import { createAction, handleActions } from 'redux-actions';
import { FETCH, FETCH_PENDING, FETCH_SUCCESS, FETCH_ERROR } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';
import { searchProductsBySkus } from 'reducers/elasticsearch';
import normalizeProduct from 'core/utils/normalizeProduct';
import { analyticsTag } from 'reducers/analytics';
import { eCommCheckout } from 'constants/analytics';
import { toggleLikeList } from 'reducers/userList';
import { eCommAddToBag, eCommRemoveFromBag, EVENTS } from 'constants/analytics';
import { setCartId, setCartExpiration } from './user';
import { LOCATIONS } from 'constants/locations';
import { pushInteraction } from './recommendations';
import log from 'core/log';
import { INTERACTION_TYPES } from 'constants/recommendations';

export const CART = 'CART';
export const CHECKOUT = 'CHECKOUT';

const staticInitialState = {
    openingTimes: [],
};

const initialState = {
    items: [],
    products: [],
    checkoutItems: [],
    checkoutProducts: [],
    isFetching: false,
    orderPlaced: false,
    checkoutError: null,
    selectedCheckoutMethod: null,
    cartLocation: LOCATIONS.NYC
};


/**
 * Accessor to retrieve the CartId from the state
 * @param {object} state
 * @returns {string} cartId
 */
const getCartId = (state) => {
    // Get cartId from profile
    const { user: { auth: { profile: { cartId } } } } = state;
    return cartId;
};

const getGuestCartExpiration = state => {
    const { user: { auth: { profile: { cartExpiration } } } } = state;
    return cartExpiration;
};

const isGuestCartExpired = state => {
    const cartExpiration = getGuestCartExpiration(state);

    if (!cartExpiration) {
        return false;
    }

    const expirationDate = new Date(cartExpiration);
    const now = new Date();

    return now.getTime() > expirationDate.getTime();
};

export const productsData = createAction('SET_CART_PRODUCTS');
export const checkoutProductsData = createAction('SET_CHECKOUT_PRODUCTS');
export const resetOrder = createAction('RESET_ORDER');
export const resetCart = createAction('RESET_CART');
export const setSelectedCheckoutMethod = createAction('SET_SELECTED_CHECKOUT_METHOD');
export const setCartLocation = createAction('SET_CART_LOCATION');
const setTimes = createAction('SET_OPENING_TIMES');

export const cartFetching = createAction('IS_FETCHING', isFetching => isFetching);

export const fetchCart = createAction(FETCH, (cartId, authenticated) => ({
    prefix: CART,
    endpoint: `${CONFIG.API_URL}/lists${!authenticated ? '/guest' : ''}/${cartId}`,
    options: {
        method: 'GET',
        noCache: true
    }
}));

export const addProductToCart = createAction(FETCH, (cartId, request, authenticated) => ({
    prefix: CART,
    endpoint: `${CONFIG.API_URL}/lists${!authenticated ? '/guest' : ''}${cartId ? `/${cartId}` : ''}/items`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

const removeSkuFromCart = createAction(FETCH, (cartId, sku, authenticated) => ({
    prefix: CART,
    endpoint: `${CONFIG.API_URL}/lists${!authenticated ? '/guest' : ''}/${cartId}/items/${sku}`,
    options: {
        method: 'DELETE'
    }
}));

const updateCartQty = createAction(FETCH, (cartId, sku, delta, authenticated) => ({
    prefix: CART,
    endpoint: `${CONFIG.API_URL}/lists${!authenticated ? '/guest' : ''}/${cartId}/items/${sku}/qty`,
    options: {
        method: 'POST',
        body: JSON.stringify({ delta })
    }
}));

const checkoutCart = createAction(FETCH, (request, authenticated) => ({
    prefix: CHECKOUT,
    endpoint: authenticated ? `${CONFIG.API_URL}/checkouts` : `${CONFIG.API_URL}/guest-checkouts`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

/**
 * Add a product (by sku) to the cart
 * @param {object} product
 * @param {boolean} quickshop
 * @returns {function(*, *): *} Promise
 */
export const addToCart = (product, quickshop) => {
    return (dispatch, getState) => {
        const state = getState();

        const cartId = getCartId(state);
        const { userList: { like } } = state;

        const request = {
            sku: product.sku,
            ean: product.ean,
            merge: true,
            limit: 3,
            userdata: {
                qty: 1,
                productCode: product.productCode,
                unitPrice: product.price
            }
        };

        dispatch(analyticsTag({
            event: EVENTS.GA,
            eventCategory: quickshop ? 'quickshop' : 'product module',
            eventAction: 'addtoyourbag',
            eventLabel: product.sku
        }, { userInfo: true }));

        const interaction = {
            skus: [product.sku],
            interactionType: INTERACTION_TYPES.ADD_TO_BAG
        };

        dispatch(pushInteraction(interaction)).catch(() => {
            log.info('Could not send interaction for recommendations:', interaction);
        });

        const authenticated = state.user.auth.authenticated;

        if (authenticated) {
            // as per the requirements, when an item is added to the cart, it's also added to the like list
            const likeItem = like.find(l => l.sku === product.sku);
            // either the item isn't in the like list or it's in the list as disliked (otherwise, it's already liked, so do nothing)
            if (!likeItem || (likeItem && !likeItem.userdata.like)) {
                dispatch(toggleLikeList(product, true, false));
            }
        }

        if (!authenticated && (!cartId || isGuestCartExpired(state))) {
            dispatch(setCartId(null));
            dispatch(setCartExpiration(null));
            dispatch(resetCart());
            return dispatch(addProductToCart(null, request, authenticated))
                .then(response => {
                    dispatch(setCartId(response.id));
                    dispatch(setCartExpiration(response.expires));
                    return Promise.resolve();
                });
        } else {
            return dispatch(addProductToCart(cartId, request, authenticated))
                .then(response => {
                    if (!authenticated) {
                        dispatch(setCartExpiration(response.expires));
                    }
                    return Promise.resolve();
                });
        }
    };
};

/**
 * Removes a product (by sku) from the cart
 * @param {string} sku
 * @returns {function(*, *): *} Promise
 */
export const removeProductFromCart = (sku) => {
    return (dispatch, getState) => {
        const state = getState();
        const cartId = getCartId(state);
        const { cart: { items, products }, user: { auth: { authenticated } } } = state;

        // clean cart if no cart or cart expired for non auth users
        if (!authenticated && (!cartId || isGuestCartExpired(state))) {
            dispatch(setCartId(null));
            dispatch(setCartExpiration(null));
            dispatch(resetCart());
            return Promise.resolve();
        }

        const item = items.find(i => i.sku === sku);
        const product = products.find(p => p.sku === sku);
        if (item && product) {
            dispatch(analyticsTag(eCommRemoveFromBag(product, item.userdata.qty), { userInfo: true }));
        }

        return dispatch(removeSkuFromCart(cartId, sku, authenticated)).then(response => {
            if (authenticated) {
                dispatch(setCartExpiration(response.expires));
            }
            const skusRemaining = response.items.map(item => item.sku);
            dispatch(productsData(products.filter(item => skusRemaining.indexOf(item.sku) >= 0)));
        });
    };
};

/**
 * Update the cart quantity
 * @param {string} sku - product sku
 * @param {number} delta - quantity delta
 * @returns {function(*, *): *} Promise
 */
export const updateCartQuantity = (sku, delta) => {
    return (dispatch, getState) => {
        const state = getState();
        const cartId = getCartId(state);
        const { cart: { items, products }, user: { auth: { authenticated } } } = state;

        // clean cart if no cart or cart expired for non auth users
        if (!authenticated && (!cartId || isGuestCartExpired(state))) {
            dispatch(setCartId(null));
            dispatch(setCartExpiration(null));
            dispatch(resetCart());
            return Promise.resolve();
        }

        const item = items.find(i => i.sku === sku);
        const product = products.find(p => p.sku === sku);
        if (item && product) {
            dispatch(analyticsTag(delta > 0 ? eCommAddToBag(product, delta) : eCommRemoveFromBag(product, delta * -1), { userInfo: true }));
        }
        return dispatch(updateCartQty(cartId, sku, delta, authenticated))
            .then(response => {
                if (authenticated) {
                    dispatch(setCartExpiration(response.expires));
                }
                return Promise.resolve();
            });
    };
};

/**
 * Returns the quantity for a given sku
 * @param {array} cartItems
 * @param {number} sku
 * @returns {number} quantity
 */
export const getItemQty = (cartItems, sku) => {
    const item = cartItems.find(i => i.sku === sku);
    return item ? item.userdata.qty : 0;
};

/**
 * Computes the total price of the cart based on its items
 * @param {array} cartItems
 * @returns {function(*, *): *} Reduce function
 */
const computeTotal = cartItems => (total, product) => total + (product || { price: 0 }).price  * getItemQty(cartItems, (product || { sku: '0' }).sku);

/**
 * Fetch the cart and the product details
 * @param {boolean} hideFetch - do not hide fetch / only used for monetate after checkout to update cart state
 * @returns {function(*, *): *} Promise
 */
export const getCart = (hideFetch = false) => {
    return (dispatch, getState) => {

        const cartId = getCartId(getState());

        // we need to hit another endpoint to get a non-logged in user's cart
        const state = getState();
        const { user: { auth: { authenticated } } } = state;

        // non-authenticated without cart or expired cart
        if (!authenticated && (!cartId || isGuestCartExpired(state))) {
            dispatch(setCartId(null));
            dispatch(setCartExpiration(null));
            dispatch(resetCart());
            return Promise.resolve();
        }

        // cart is fetching
        if (!hideFetch) {
            dispatch(cartFetching(true));
        }

        // fetch cart from Api
        return dispatch(fetchCart(cartId, authenticated))
            .then(response => {
                if (authenticated) {
                    dispatch(setCartExpiration(response.expires));
                }

                const { cart: { items } } = getState();
                const productSkus = items.map(i => i.sku);
                // if no skus (i.e. empty cart), don't call search
                return productSkus.length > 0
                    ? dispatch(searchProductsBySkus(productSkus))
                    : null;
            })
            .then(response => {
                // product data has been fetched, normalize and add to cart state
                if (response) {
                    dispatch(productsData(response.docs.map(normalizeProduct).filter(p => p)));
                }
                dispatch(cartFetching(false));
            })
            .catch(() => dispatch(cartFetching(false)));
    };
};

/**
 * Computed property (could be optimized: https://redux.js.org/recipes/computing-derived-data)
 * @param {array} items - cart items
 * @param {array} products - product data
 * @returns {number} cart subtotal
 */
export const getSubtotal = ({ items, products }) => {
    return products.reduce(computeTotal(items), 0);
};

/**
 * Check for product is already in cart with max ammount for pruchase (3 for any SKU)
 * @param  {array} cartItems - array of items in user cart
 * @param  {string} productID - Product sku
 * @return { boolean } - product purchase limit reached
 */
export const isPurchaseLimitReached = (cartItems, productID) => {
    return !!cartItems.filter(item => item.sku === productID && item.userdata.qty === item.limit).length;
};

/**
 * Check qty of specific item in the cart
 * @param {object} cartItems
 * @param {string} sku
 * @returns {number} qty
 */
export const qtyItemInCart = (cartItems, sku) => {
    const itemInCart = cartItems.find(item => item.sku === sku);
    return itemInCart ? itemInCart.userdata.qty : 0;
};

/**
 * Prepares the cart items to be sent to an external checkout endpoint, base64 encoded
 * @param {object} cart
 * @returns {string} base64 encoded cart entries
 */
export const getCartExternalCheckoutBase64Hash = (cart) => {
    if (!cart) {
        return '';
    }

    const skusAvailableOnline = cart.products.filter(p => p && p.availableOnline).map(p => p.sku);
    const requestBody = {
        formOrigin: 'One-Hope',
        dataType: 'Cart',
        cart: {
            entries: cart.items.filter(i => skusAvailableOnline.includes(i.sku)).map(i => {
                return { sku: i.sku, quantity: i.userdata.qty };
            })
        }
    };

    return btoa(JSON.stringify(requestBody));
};

/**
 * Get products that are in the cart based on a filter function
 * @param {function} productFilter
 * @return {Function} getProducts from cart
 */
const getProducts = (productFilter) => (cart, checkout = false) => {
    if (!cart) {
        return [];
    }
    const filteredProducts = checkout ? cart.checkoutProducts.filter(productFilter) : cart.products.filter(productFilter);
    const cartSkus = checkout ? cart.checkoutItems.map(i => i.sku) : cart.items.map(i => i.sku);

    return filteredProducts.filter(p => cartSkus.includes(p.sku)).map(p => {
        return {
            ...p,
            quantity: checkout ? cart.checkoutItems.find(i => i.sku === p.sku).userdata.qty : cart.items.find(i => i.sku === p.sku).userdata.qty
        };
    });
};


/**
 * Returns the list of products that are in the cart and available for online checkout
 * @return {array} online products
 */
export const getOnlineProducts = getProducts(p => p.availableOnline);

/**
 * Returns the list of products that are in the cart and available in the Atelier
 * @return {array} atelier products
 */
export const getAtelierProducts = getProducts(p => p.availableInStore);

/**
 * Returns the list of products that are in the cart and available in the Austin Atelier
 * @return {array} atelier products
 */
export const getAtelierProducts2 = getProducts(p => p.availableInStore2);

/**
 * Checkout at the Atelier
 * @param {string} email - Email when trying to do a guest checkout
 * @param {string} firstName - First Name when trying to do a guest checkout
 * @param {string} lastName - Last Name when trying to do a guest checkout
 * @param {string} phone - Phone when trying to do a guest checkout
 * @return {function(*, *): *} Checkout Promise
 */
export const checkout = (email, firstName, lastName, phone) => {
    return (dispatch, getState) => {
        const {
            user: {
                auth: {
                    authenticated,
                    profile: { cartId }
                }
            },
            cart: { items, products, cartLocation }
        } = getState();

        // only checkout products that are available in store
        const productsAvailableInStore = products
            .filter(
                p =>
                    p[
                        cartLocation === LOCATIONS.AUSTIN
                            ? 'availableInStore2'
                            : 'availableInStore'
                    ]
            );
        const skusAvailableInStore = productsAvailableInStore.map(p => p.sku);

        const cartItems = items
            .filter(i => skusAvailableInStore.includes(i.sku))
            .map(i => {
                return {
                    sku: i.sku,
                    ean: i.ean,
                    userdata: {
                        qty: i.userdata.qty,
                        productCode: i.productCode,
                        unitPrice: i.price
                    }
                };
            });

        let request = {
            cart: cartItems,
            location: cartLocation,
            cartId
        };

        if (!authenticated) {
            request = { ...request, email, firstName, lastName, phone };
        }

        analyticsTag(
            eCommCheckout(
                'atelier',
                cartLocation === LOCATIONS.AUSTIN
                    ? getAtelierProducts2()
                    : getAtelierProducts()
            ),
            { userInfo: true }
        );
        analyticsTag(
            {
                event: EVENTS.GA,
                eventCategory: 'cart',
                eventAction: 'atelier',
                eventLabel: 'placeorder'
            },
            { userInfo: true }
        );

        return dispatch(checkoutCart(request, authenticated)).then(() => {
            dispatch(
                analyticsTag(
                    {
                        url: window.location.href,
                        event: EVENTS.GA,
                        eventCategory: 'cart',
                        eventAction: 'atelier',
                        eventLabel: 'ordersuccess'
                    },
                    { userInfo: true }
                )
            );

            dispatch(checkoutProductsData(products));
            dispatch(getCart());
        });
    };
};

/**
 * Reset cart to its initial state and fetch cart to get the latest
 * @return {Function} reset cart to initial state
 */
export const reset = () => {
    return (dispatch) => {
        dispatch(resetCart());
        dispatch(getCart(true));
    };
};

/**
 * Set pickup times
 * @param {array} openHours - Array containing strings coming from prismic
 * with HH:mm/HH:mm format for each weekday starting on Sunday.
 * @return {Function} sets pickuptimes in redux state
*/
export const setOpenHours = openHours => {
    return dispatch => {
        const normalizedTimes = openHours.map(pt => {
            // HH:mm/HH:mm regex
            const isValid = /^([01]\d|2[0-3]):?([0-5]\d)\/([01]\d|2[0-3]):?([0-5]\d)$/g.test(pt);

            const pickupTime = {
                isAvailable: isValid
            };

            if (isValid) {
                const times = pt.split('/').map(t => t.split(':'));
                pickupTime.openHour = times[0][0];
                pickupTime.openMinute = times[0][1];
                pickupTime.closeHour = times[1][0];
                pickupTime.closeMinute = times[1][1];
            }

            return pickupTime;
        });
        dispatch(setTimes(normalizedTimes));
    };
};

export default handleActions(
    {
        [cartFetching]: (state, { payload }) => ({ ...state, isFetching: payload }),
        [resetOrder]: (state) => ({ ...state, orderPlaced: false }),
        [`${CART}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            items: [...payload.items],
        }),
        [`${CHECKOUT}/${FETCH_PENDING}`]: (state) => ({ ...state, isFetching: true }),
        [`${CHECKOUT}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            checkoutItems: [...payload.cart],
            orderPlaced: true,
            isFetching: false,
            checkoutError: null
        }),
        [`${CHECKOUT}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            checkoutError: payload
        }),
        [productsData]: (state, { payload }) => ({
            ...state,
            products: payload,
        }),
        [checkoutProductsData]: (state, { payload }) => ({
            ...state,
            checkoutProducts: payload
        }),
        [setTimes]: (state, { payload }) => ({
            ...state,
            openingTimes: payload
        }),
        [resetCart]: (state) => ({
            ...state,
            ...initialState
        }),
        [setSelectedCheckoutMethod]: (state, { payload }) => ({
            ...state,
            selectedCheckoutMethod: payload
        }),
        [setCartLocation]: (state, { payload }) => ({
            ...state,
            cartLocation: payload
        })
    },
    { ...initialState, ...staticInitialState }
);
