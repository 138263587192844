import { AccordionItemStyled } from 'components/GlobalComponents/Accordion';
import Button from 'components/GlobalComponents/Button/Button';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import { LAYER } from 'constants/layers';
import { breakpoints } from 'constants/theme';
import { greaterThan, rem, styledProps } from 'core/styled';
import {
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: ${rem(450)};
    padding: ${rem(35, 0, 0)};
    position: relative;

    ${greaterThan(breakpoints.medium)(css`
        max-width: ${rem(1440)};
    `)} // min-height: 100vh;
`;

export const Header = styled.h1`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(18)};
    line-height: ${rem(22)};
    text-align: center;
    font-weight: bold;
    margin: 0;

    ${greaterThan(breakpoints.medium)(css`
        font-size: ${rem(36)};
        line-height: ${rem(40)};
    `)}
`;

export const TabsWrapper = styled.div`
    width: 100%;
    margin-top: ${rem(15)};
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: ${rem(60)};
    background-color: ${styledProps('color', 'white')};
    z-index: ${LAYER.FIXED_TABS};

    ${greaterThan(breakpoints.small)(css`
        top: ${rem(80)};
    `)};
`;

export const TabItemsWrapper = styled.div`
    padding: ${rem(0, 25)};
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    gap: ${rem(40)};
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    ${greaterThan(breakpoints.medium)(css`
        gap: ${rem(50)};
    `)}
`;

export const TabItem = styled(Link)`
    cursor: pointer;
    color: ${styledProps('color', 'adaGrey')};
    font-size: ${rem(10)};
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    text-align: center;
    white-space: nowrap;
    height: ${rem(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: ${rem(2, 0)};
    border-color: ${styledProps('color', 'transparent')};
    border-style: solid;
    transition: border-color 0.2s ease-in-out;
    text-decoration: none;

    ${greaterThan(breakpoints.medium)(css`
        font-size: ${rem(14)};
    `)}

    ${props =>
        props.isActive
            ? css`
                  border-bottom-color: ${styledProps('color', 'black')(props)};
                  color: ${styledProps('color', 'black')};
              `
            : null}
`;

export const TabContent = styled.div`
    width: 100%;
    max-width: ${rem(450)};
    display: flex;
    flex-direction: column;
    align-items: center;

    ${greaterThan(breakpoints.medium)(css`
        max-width: ${rem(1440)};
    `)}
`;

export const HeroImageWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 106.4%;

    ${greaterThan(breakpoints.medium)(css`
        padding-bottom: 31.2%;
    `)}

    img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: auto;
    }
`;

export const HeroTextDesktopWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: none;
    flex-direction: row-reverse;
    align-items: center;

    ${greaterThan(breakpoints.medium)(css`
        display: flex;
    `)}
`;

export const HeroTextMobileWrapper = styled.div`
    ${greaterThan(breakpoints.medium)(css`
        display: none;
    `)}
`;

export const HeroTextDesktop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: ${rem(0, 30)};
    color: ${({ white }) => styledProps('color', white ? 'white' : 'black')};

    h2 {
        max-width: ${rem(385)};
        margin: 0 auto;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(20)};
        text-align: center;
        margin: 0;
        font-weight: normal;
    }

    p {
        max-width: ${rem(385)};
        font-family: ${styledProps('font', 'default')};
        font-weight: 300;
        text-align: center;
        font-size: ${rem(14)};
        margin-top: ${rem(10)};
        line-height: 1.5;
    }

    .nowrap {
        white-space: nowrap;
    }
`;

export const HeroTextMobile = styled.div`
    margin-top: ${rem(20)};
    width: 100%;
    padding: ${rem(0, 20)};

    h2 {
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(16)};
        text-align: center;
        margin: 0;
        font-weight: normal;
    }

    p {
        font-family: ${styledProps('font', 'default')};
        font-weight: 300;
        text-align: center;
        font-size: ${rem(14)};
        margin-top: ${rem(10)};
        line-height: 1.5;
    }

    .nowrap {
        white-space: nowrap;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    max-width: ${rem(450)};
    padding: ${rem(0, 20)};
    display: flex;
    flex-direction: column;
    align-items: center;

    ${greaterThan(breakpoints.medium)(css`
        max-width: ${rem(1440)};
    `)}
`;

export const ItemsHeader = styled.div`
    width: 100%;
    margin-top: ${rem(120)};

    h3 {
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(16)};
        text-align: center;
        margin: 0;
        font-weight: normal;
    }

    p {
        font-family: ${styledProps('font', 'default')};
        font-weight: 300;
        text-align: center;
        font-size: ${rem(14)};
        margin-top: ${rem(10)};
        line-height: 1.5;
    }

    .nowrap {
        white-space: nowrap;
    }

    ${greaterThan(breakpoints.medium)(css`
        margin-top: ${rem(70)};

        h3 {
            font-size: ${rem(20)};
        }
    `)}
`;

export const MainItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: ${rem(36)};
    gap: ${rem(22)};

    .full-width {
        width: 100%;
        max-width: ${rem(488)};
    }

    .image-container {
        width: 100%;
        position: relative;
        padding-bottom: 130.9%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .text-container {
        width: 100%;
        max-width: ${rem(488)};
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ${greaterThan(breakpoints.medium)(css`
        gap: ${rem(28)};
        margin-top: ${rem(72)};
        flex-direction: ${({ index }) =>
        index % 2 === 0 ? 'row' : 'row-reverse'};
        justify-content: center;
    `)}
`;

export const ItemTextWrapper = styled.div`
    width: 100%;
    max-width: ${({ main }) => rem(main ? 385 : 575)};

    ${({ isBottom }) => (isBottom ? `margin-top: ${rem(20)};` : '')}

    h3,
    h4 {
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(16)};
        text-align: center;
        margin: 0;
        font-weight: normal;
    }

    p {
        font-family: ${styledProps('font', 'default')};
        font-weight: 300;
        text-align: center;
        font-size: ${rem(14)};
        margin-top: ${rem(10)};
        line-height: 1.5;

        a {
            text-decoration: underline;
        }
    }

    .nowrap {
        white-space: nowrap;
    }

    .font-small {
        font-size: 0.85em;
    }

    .font-chanel {
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(12)};
    }

    ${greaterThan(breakpoints.medium)(css`
        h3,
        h4 {
            font-size: ${rem(20)};
        }
    `)}
`;

export const DiscoverButton = styled(Button)`
    margin-top: ${rem(25)};
    width: ${rem(140)};
    height: ${rem(43)};
    border-width: ${rem(1)};
    font-size: ${rem(10)};
    padding: ${rem(0, 10)};
`;

export const SmallItemsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: ${rem(36, 28)};
    margin-top: ${rem(25)};

    ${greaterThan(breakpoints.medium)(css`
        margin-top: ${rem(70)};
        gap: ${rem(70, 28)};
    `)}
`;

export const SmallItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-container {
        width: 100%;
        position: relative;
        padding-bottom: 62.5%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: ${rem(20)};
    }

    ${greaterThan(breakpoints.medium)(css`
        width: ${({ isTitle }) =>
        isTitle ? '100%' : `calc(50% - ${rem(14)})`};
    `)}
`;

export const QuestionAccordionWrapper = styled.div`
    width: 100%;
    max-width: ${rem(600)};
    margin-top: ${rem(30)};
`;

export const QuestionAccordionItem = styled(AccordionItemStyled)`
    border-bottom: ${rem(1)} solid ${styledProps('color', 'gallery')};
`;

export const QuestionAccordionItemHeadingWrapper = styled(AccordionItemHeading)`
    outline: none;
    position: relative;
`;

export const QuestionTitle = styled.p`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(12)};
    line-height: ${rem(16)};
    margin: ${rem(11, 0)};
    padding-left: ${rem(15)};
    padding-right: ${rem(35)};

    /* ${greaterThan(breakpoints.medium)(css`
        margin: ${rem(14, 0)};
    `)}; */
`;

export const QuestionArrow = styled.div`
    transform: translateY(-50%);
    margin-top: 0 !important;
`;

export const QuestionAccordionItemPanel = styled(AccordionItemPanel)`
    margin: 0;
    padding: ${rem(20)} 0;

    &.accordion__body--hidden {
        display: none;
    }
`;

export const QuestionAnswerWrapper = styled.div`
    text-align: left;
    padding: ${rem(0, 15)};
    margin-top: ${rem(-15)};
    color: ${styledProps('color', 'mineShaft')};

    p {
        font-family: ${styledProps('font', 'default')};
        font-weight: 300;
        font-size: ${rem(14)};
        margin-top: ${rem(10)};
        line-height: 1.5;
    }

    a {
        text-decoration: underline;
    }
`;

export const EventsWrapper = styled.div`
    width: 100%;
    max-width: ${rem(1200)};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${rem(70)};
    border-color: ${styledProps('color', 'gallery')};
    border-style: solid;
    border-width: ${({ withEvents }) => rem(withEvents ? 1 : 0, 0)};
`;

export const EventItem = styled.div`
    width: 100%;
    max-width: ${rem(800)};
    padding: ${rem(30, 10)};
    border-top: ${({ first }) => rem(first ? 0 : 1)} solid
        ${styledProps('color', 'gallery')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${rem(100)};
    ${({ isHiddenEvent }) => (isHiddenEvent ? `opacity: 0.7;` : null)}

    .event-text-pane {
        width: 100%;
        display: flex;
        flex-direction: column;

        .event-hidden {
            margin-bottom: ${rem(15)};
            font-family: ${styledProps('font', 'ABChanelCorpo')};
            font-size: ${rem(12)};
            text-align: left;
            line-height: 1.5;
        }

        .event-date {
            font-family: ${styledProps('font', 'ABChanelCorpo')};
            font-size: ${rem(12)};
            text-align: left;
            margin: 0;
            line-height: 1.5;
        }

        .event-title {
            font-family: ${styledProps('font', 'ABChanelPBM')};
            font-weight: bold;
            font-size: ${rem(16)};
            text-align: left;
            margin: ${rem(15, 0, 0)};
            line-height: 1.35;

            ${greaterThan(breakpoints.medium)(css`
                font-size: ${rem(20)};
            `)}
        }

        .event-description {
            font-family: ${styledProps('font', 'default')};
            font-weight: 300;
            font-size: ${rem(14)};
            text-align: left;
            margin: ${rem(15, 0, 0)};
            line-height: 1.5;
        }

        .event-price-duration {
            font-family: ${styledProps('font', 'ABChanelCorpo')};
            font-size: ${rem(12)};
            text-align: left;
            margin: ${rem(15, 0, 0)};
            line-height: 1.5;
        }

        .event-button-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: flex-start;
            justify-content: space-between;
            margin-top: ${rem(25)};
            width: 100%;

            .event-pseudo-buttons {
                display: flex;
                gap: ${rem(30)};
            }
        }
    }

    .event-button-pane {
        display: none;

        ${greaterThan(breakpoints.medium)(css`
            display: block;
        `)}
    }
`;

export const EventButton = styled(Button)`
    width: ${rem(166)};
    height: ${rem(30)};
    font-size: ${rem(10)};
    color: ${styledProps('color', 'white')};
    background-color: ${styledProps('color', 'black')};
    border-width: ${rem(1)};

    &:hover,
    &:focus {
        color: ${styledProps('color', 'black')};
        background-color: ${styledProps('color', 'white')};
    }

    &:disabled,
    &:disabled:hover {
        color: ${styledProps('color', 'white')};
        background-color: ${styledProps('color', 'adaGrey')};
    }

    ${greaterThan(breakpoints.medium)(css`
        ${({ isMobile }) => (isMobile ? 'display: none;' : '')}
    `)}
`;

export const DiscoverPseudoLink = styled(Button).attrs({
    variant: 'pseudoLink'
})`
    font-size: ${rem(10)};
    margin: ${({ cssMargin }) =>
        cssMargin ? rem(...cssMargin) : rem(0, 0, 2)};
`;

export const NoEventsText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: ${styledProps('color', 'mineShaft')};

    .font-chanel {
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        font-size: ${rem(12)};
        line-height: 1.3;
    }
`;

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
`;

export const ModalCloseButton = styled(NoStyleButton)`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(50)};
    height: ${rem(50)};
    align-self: flex-end;
    margin: ${rem(15, -15, 10, 0)};

    img {
        display: block;
        width: ${rem(15)};
        height: ${rem(15)};
    }

    ${greaterThan(breakpoints.medium)(css`
        margin: ${rem(15, -15, 10, 0)};
    `)};
`;

export const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    max-width: ${rem(720)};
    width: 100%;
`;

export const ModalTitle = styled.h4`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(16)};
    text-align: center;
    margin: ${rem(15, 0, 0)};
    line-height: 1.35;

    ${greaterThan(breakpoints.medium)(css`
        font-size: ${rem(20)};
    `)}
`;

export const ModalDate = styled.p`
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-size: ${rem(12)};
    text-align: center;
    margin: 0;
    line-height: 1.5;
`;

export const ModalDescription = styled.div`
    font-family: ${styledProps('font', 'default')};
    font-weight: 300;
    font-size: ${rem(14)};
    text-align: center;
    margin: ${({ bottom }) => rem(bottom ? 20 : 15, 0, 0)};
    line-height: 1.5;

    strong {
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(12)};
    }

    p:not(:first-child) {
        margin-top: ${rem(20)};
    }

    ul {
        margin-top: ${rem(15)};

        li {
            margin-top: ${rem(5)};
            display: list-item;

            &::before {
                content: '-';
                padding-right: ${rem(8)};
            }
        }
    }

    .nowrap {
        white-space: nowrap;
    }
`;

export const ModalImage = styled.img`
    display: block;
    width: ${rem(230)};
    height: auto;
    margin-top: ${rem(20)};
`;

export const ModalDatePrice = styled.p`
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-size: ${rem(12)};
    text-align: center;
    margin: ${rem(25, 0, 0)};
    line-height: 1.5;
`;

export const NoEvents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${rem(50, 0)};
    text-align: center;

    h3 {
        margin: 0;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-size: ${rem(12)};
    }

    p {
        margin-top: ${rem(10)};
        font-family: ${styledProps('font', 'default')};
        font-size: ${rem(12)};
        line-height: 1.5;
        font-weight: 300;

        a {
            text-decoration: underline;
        }
    }
`;

export const PrivateEventBotStyle = createGlobalStyle`
    .floated-chat-w .floated-chat-i {
        width: 100% !important;
        max-width: ${rem(390)} !important;
        box-shadow: ${rem(0, 2, 40, 0)} rgba(0, 0, 0, 0.21) !important;

        #btn-close {
            background-color: transparent;
            cursor: pointer;
        }

        #btn-message-send {
            span {
                position: relative;
                top: ${rem(3)};
            }
        }
    }

    @media screen and (max-width: ${rem(500)}) {
        .floated-chat-w {
            padding-left: ${rem(10)};
        }
    }

    #chat-messages {
        overscroll-behavior: contain;
    }
`;
